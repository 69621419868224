@import "tailwindcss/base";
@import "nprogress/nprogress.css";

@layer base {
  html {
    /*@apply font-manrope !important;*/
  }

  *,
  ul,
  p {
    @apply m-0 p-0 list-none box-border outline-none scroll-smooth;
  }

  a {
    @apply no-underline cursor-pointer hover:no-underline;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  ::-webkit-scrollbar {
    @apply w-2;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-elLightBorderColor rounded-full;
  }
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.main {
  margin-top: 80px;
}

body {
  background: #ffffff;
  overflow-y: scroll;
  min-width: 320px;
}

@media (max-width: 1250px) {
  .container {
      width: 100%;
      max-width: 992px;
      padding: 0 15px;
  }
}

@media (max-width: 992px) {
  .container {
      max-width: 768px;
  }

  .main {
      margin-top: 70px;
  }
}

@media (max-width: 768px) {
  .container {
      max-width: 576px;
  }

  * {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
  }
}

@media (max-width: 576px) {
  .container {
      max-width: unset;
  }
}
