@import "tailwindcss/components";
@import "nprogress/nprogress.css";

@layer components {
  /*react-daterange-picker*/
  .date-range-picker {
    @apply bg-mainBgLight py-2 px-2 border text-mainBlackTextColor border-inputLightBorderColor rounded-[12px] dark:bg-inputDarkBg dark:text-mainLightTextColor;
  }

  .date-range-picker .react-daterange-picker__wrapper {
    border: none;
  }

  .date-range-picker .react-calendar {
    @apply mt-1 py-2 border-none bg-mainBgLight rounded-[12px] shadow-[4px_12px_32px_rgba(93,106,131,0.1)] dark:bg-inputDarkBg dark:border-inputDarkBorderColor;
  }

  .date-range-picker .react-daterange-picker__range-divider {
    margin-right: 5px;
  }

  .date-range-picker .react-daterange-picker__inputGroup__input:invalid {
    background: none;
  }

  .date-range-picker .react-calendar__tile--active {
    @apply bg-mainColor hover:bg-mainColor;
  }

  .date-range-picker .react-calendar__tile--active:enabled,
  .date-range-picker .react-calendar__tile--active:enabled {
    @apply hover:bg-mainColor focus:bg-mainColor;
  }

  .date-range-picker .react-calendar__tile--now {
    @apply bg-mainColor text-mainLightTextColor hover:bg-mainColor;
  }

  .date-range-picker .react-calendar__tile--now:enabled {
    @apply hover:bg-mainColor;
  }

  /* Pagination */
  .pagination {
    @apply flex items-center gap-5 cursor-pointer;
  }

  .pagination .active-page {
    @apply flex justify-center items-center border border-mainColor rounded-[8px] !text-mainColor font-bold w-8 h-8 p-4;
  }

  .pagination .page-item {
    @apply cursor-pointer text-mainGray dark:text-mainLightTextColor;
  }

  .pagination .page-item:hover {
    @apply !text-mainColor;
  }

  .pagination .break-item {
    @apply text-mainGray dark:text-mainLightTextColor;
  }

  .pagination .prev-next-disabled {
    @apply opacity-30 pointer-events-none select-none;
  }

  /* React-Select */
  .react-select-container {
    @apply w-full;
  }
  .react-select-container .my-react-select__control {
    /* @apply bg-mainBgLight border border-inputLightBorderColor rounded-[12px] dark:border-inputDarkBorderColor dark:bg-mainBgDark; */
    @apply bg-mainBgLight border border-inputLightBorderColor rounded-[12px] dark:bg-inputDarkBg dark:border-inputDarkBorderColor focus:border-mainColor focus:dark:border-mainColor;
  }

  .react-select-container .my-react-select__control--is-focused {
    @apply border-inputLightBorderColor hover:border-inputLightBorderColor shadow-none focus:border-mainColor focus:dark:border-mainColor;
  }

  .react-select-container .my-react-select__menu {
    @apply bg-mainBgLight dark:bg-mainGreen;
  }

  .react-select-container .my-react-select__option {
    @apply text-mainBlackTextColor dark:text-mainLightTextColor bg-white hover:bg-gray-50 cursor-pointer dark:hover:bg-slate-900;
  }

  .react-select-container .my-react-select__indicator-separator {
    @apply bg-transparent;
  }

  /* .react-select-container .my-react-select__input-container,
  .react-select-container .my-react-select__placeholder,
  .react-select-container .my-react-select__single-value {
    @apply text-neutral-600;
  }

  .react-select-container .css-1p3m7a8-multiValue {
    @apply bg-[#E6E6E6] text-black dark:bg-[#555] dark:text-white;
  }

  .react-select-container .css-wsp0cs-MultiValueGeneric {
    @apply text-[#333] dark:text-white;
  } */
}

#nprogress .bar {
  @apply bg-mainColor;
}

#nprogress .spinner-icon {
  /*@apply border-l-[#0046f9] border-t-[#0046f9];*/
  @apply hidden;
}

#nomobile {
  @apply hidden;
}

#card-border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%23F8635EFF' stroke-width='4' stroke-dasharray='4 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
}

#home-border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23F8635EFF' stroke-width='2' stroke-dasharray='2%2c 7' stroke-dashoffset='11' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
}

#empty-card-border-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23F8635EFF' stroke-width='3' stroke-dasharray='2%2c 7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
}

.base-skeleton-loader {
  position: relative;
  overflow: hidden;
  background-color: #e2e8f0;

}
.base-skeleton-loader::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
}
